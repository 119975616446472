import { Routes, Route, Navigate, RoutesProps, } from "react-router-dom";
import React, { Suspense, useEffect } from "react";
import { useState } from "react";
import ProtectedRoute from "./ProtectedRoute";
import Main from "../components/Main";
import { useSelector } from "react-redux";
import { RootState } from "../config/Store";
import { UserState } from "../reducer/AuthReducer";
import Login from "../components/Login/Login";
import Signup from "../components/Login/Signup";
import ForgotPassword from "../components/Login/ForgotPassword";
import Otp from "../components/Login/Otp";
import ResetPassword from "../components/Login/ResetPassword";
import Dummy from "../components/Dummy/Dummy";
import HelperService from "../Services/HelperService";

const Dashboard = React.lazy(() => import("../pages/Dashboard/Dashboard"));
const Catalog = React.lazy(() => import("../pages/Catalog/Catalog"));
const Profile = React.lazy(() => import("../pages/Profile/Profile"));
const DocDetail = React.lazy(() => import("../pages/DocDetail/DocDetail"));
const Cart = React.lazy(() => import("../pages/Cart/Cart"));
const Checkout = React.lazy(() => import("../pages/Checkout/Checkout"));
const Review = React.lazy(() => import("../pages/Checkout/Review"));
const Subscriptions = React.lazy(() => import("../pages/Subscriptions/Subscriptions"));
const PaymentInfo = React.lazy(() => import("../pages/PaymentInformation/PaymentInformation"));
const Invoices = React.lazy(() => import("../pages/Invoices/Invoices"));
const ViewInvoice = React.lazy(() => import("../pages/Invoices/ViewInvoice"));
const Renewals = React.lazy(() => import("../pages/Renewals/Renewals"));
const Renew = React.lazy(() => import("../pages/Renewals/Renew"));
const Accounts = React.lazy(() => import("../pages/Accounts/Accounts"));
const AddUser = React.lazy(() => import("../pages/Accounts/AddUser"));
const Addresses = React.lazy(() => import("../pages/Addresses/Addresses"));
const AddAddresses = React.lazy(() => import("../pages/Addresses/AddAddress"));
const PhoneNumber = React.lazy(() => import("../pages/PhoneNumbers/PhoneNumbers"));
const AddPhoneNumber = React.lazy(() => import("../pages/PhoneNumbers/AddPhoneNumber"));
const CategoryDashboard = React.lazy(() => import("../pages/ContryDashboard/ContryDashboard"));
const Australia = React.lazy(() => import("../pages/ContryDashboard/Australia"));
const DocumentDetail = React.lazy(() => import("../components/DocumentDetailPage/documentDetail"));
const PdfDetailPage = React.lazy(() => import("../components/PdfDetailPage/PdfDoc"));
const ExcelDetailPage = React.lazy(() => import("../components/ExcelDetailPage/ExcelDoc"));
const Search = React.lazy(() => import("../pages/Search/Search"));
const PayStatus = React.lazy(() => import("../pages/PaymentStatus/PayStatus"));
const NotificationPage=React.lazy(() => import("../pages/Notification/Notification"));
const PaypalStatusSuccess=React.lazy(() => import("../components/Paypal/PaypalStatusSuccess"));
const PaypalStatusCancel=React.lazy(() => import("../components/Paypal/PaypalStatusCancel"));
const DocumentAppView=React.lazy(() => import("../components/DocumentAppView/Document"));
const DeleteAccount=React.lazy(() => import("../components/DeleteAccount/DeleteAccount"));





interface ProtectedRouteProps extends RoutesProps {
  isAuthenticated: boolean
  authenticationPath: string;
}

const Navigation = () => {
  const userInfoData: any = useSelector<RootState, any>((state: any) => state.userInfoData);
  const [isStaff, setIsStaff] = useState<any>()



  const login: any = useSelector<RootState, UserState>((state: any) => state.userLogin);
  const defaultProtectedRouteProps: Omit<ProtectedRouteProps, "outlet"> = {
    isAuthenticated: localStorage.getItem("token") != null,
    authenticationPath: "/login",
  };


  

  useEffect(() => {


    if (
      userInfoData &&
      userInfoData?.user_info &&
      !HelperService.isEmptyObject(userInfoData?.user_info)
    ) {
      setIsStaff(userInfoData?.user_info.is_staff)
    }
  }, [userInfoData?.user_info]);




  return (
    <>
      <div id="main-wraper">
        <Routes>
        <Route path="/app-view"  element={<Suspense fallback={<></>}> <DocumentAppView /> </Suspense>} />

          {/* ///////////////////////////////////////////////////////////////////////////////////////////////// */}
          <Route path="/" element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<Main />} />}>
            <Route path="*" element={<Dummy />} />
            {isStaff ?
              <>
                <Route path="/catalog" element={<Suspense fallback={<></>}> <Catalog /> </Suspense>} />
                <Route path="/search" element={<Suspense fallback={<></>}> <Search /> </Suspense>} />
                <Route path="/profile" element={<Suspense fallback={<></>}> <Profile /> </Suspense>} />
                <Route path="/category-detail" element={<Suspense fallback={<></>}> <CategoryDashboard /> </Suspense>} />
                <Route path="/document-detail" element={<Suspense fallback={<></>}> <DocumentDetail /> </Suspense>} />
                <Route path="/pdf-detail" element={<Suspense fallback={<></>}> <PdfDetailPage /> </Suspense>} />
                <Route path="/excel-detail" element={<Suspense fallback={<></>}> <ExcelDetailPage /> </Suspense>} />
                <Route path="/doc-detail/:id" element={<Suspense fallback={<></>}> <DocDetail /> </Suspense>} />
                <Route path="/notification" element={<Suspense fallback={<></>}> <NotificationPage /> </Suspense>} />
                <Route path="/app-view"  element={<Suspense fallback={<></>}> <DocumentAppView /> </Suspense>} />
              </>
              :
              <>
                <Route path="/search" element={<Suspense fallback={<></>}> <Search /> </Suspense>} />
                {/* <Route path="/dashboard" element={<Suspense fallback={<></>}> <Dashboard /> </Suspense>} /> */}
                <Route path="/catalog" element={<Suspense fallback={<></>}> <Catalog /> </Suspense>} />
                <Route path="/dashboard" element={<Suspense fallback={<></>}> <Dashboard /> </Suspense>} />
                <Route path="/profile" element={<Suspense fallback={<></>}> <Profile /> </Suspense>} />
                <Route path="/doc-detail/:id/:doc_id" element={<Suspense fallback={<></>}> <DocDetail /> </Suspense>} />
                <Route path="/cart" element={<Suspense fallback={<></>}> <Cart /> </Suspense>} />
                <Route path="/checkout" element={<Suspense fallback={<></>}> <Checkout /> </Suspense>} />
                <Route path="/review" element={<Suspense fallback={<></>}> <Review /> </Suspense>} />
                <Route path="/subscriptions" element={<Suspense fallback={<></>}> <Subscriptions /> </Suspense>} />
                <Route path="/payment-info" element={<Suspense fallback={<></>}> <PaymentInfo /> </Suspense>} />
                <Route path="/renewals" element={<Suspense fallback={<></>}> <Renewals /> </Suspense>} />
                <Route path="/renewals/renew/:id" element={<Suspense fallback={<></>}> <Renew /> </Suspense>} />
                <Route path="/invoices" element={<Suspense fallback={<></>}> <Invoices /> </Suspense>} />
                <Route path="/invoices/view/:id" element={<Suspense fallback={<></>}> <ViewInvoice /> </Suspense>} />
                <Route path="/accounts" element={<Suspense fallback={<></>}> <Accounts /> </Suspense>} />
                <Route path="/accounts/add-user" element={<Suspense fallback={<></>}> <AddUser /> </Suspense>} />
                <Route path="/addresses" element={<Suspense fallback={<></>}> <Addresses /> </Suspense>} />
                <Route path="/addresses/add-address" element={<Suspense fallback={<></>}> <AddAddresses /> </Suspense>} />
                <Route path="/phone-number" element={<Suspense fallback={<></>}> <PhoneNumber /> </Suspense>} />
                <Route path="/phone-number/add-number" element={<Suspense fallback={<></>}> <AddPhoneNumber /> </Suspense>} />
                <Route path="/category-detail" element={<Suspense fallback={<></>}> <CategoryDashboard /> </Suspense>} />
                <Route path="/australia" element={<Suspense fallback={<></>}> <Australia /> </Suspense>} />
                <Route path="/document-detail" element={<Suspense fallback={<></>}> <DocumentDetail /> </Suspense>} />
                <Route path="/pdf-detail" element={<Suspense fallback={<></>}> <PdfDetailPage /> </Suspense>} />
                <Route path="/excel-detail" element={<Suspense fallback={<></>}> <ExcelDetailPage /> </Suspense>} />
                <Route path="/excel-detail" element={<Suspense fallback={<></>}> <ExcelDetailPage /> </Suspense>} />
                <Route path="/payStatus" element={<Suspense fallback={<></>}> <PayStatus /> </Suspense>} />
                <Route path="/notification" element={<Suspense fallback={<></>}> <NotificationPage /> </Suspense>} />
                <Route path="/paypal/success"  element={<Suspense fallback={<></>}> <PaypalStatusSuccess /> </Suspense>} />
                <Route path="/paypal/cancel"  element={<Suspense fallback={<></>}> <PaypalStatusCancel /> </Suspense>} />
                <Route path="/app-view"  element={<Suspense fallback={<></>}> <DocumentAppView /> </Suspense>} />

                
   

              </>}
          </Route>
          {/* //////////////////////////////////////////////////////////////////////////////////////////////// */}


          {/* <Route path="*" element={<Navigate replace to="/login" />} /> */}
          <Route path="*" element={<Dummy />} />
          <Route path="/" element={<Dummy />} />
          <Route path="/login" element={defaultProtectedRouteProps.isAuthenticated || login.loginSuccess ? (
            <Navigate replace to={userInfoData?.user_info.is_staff ? "/catalog" : "/catalog"} />) : (<Login />)} />
          <Route path="/delete-account" element={defaultProtectedRouteProps.isAuthenticated || login.loginSuccess ? (
            <Navigate replace to={userInfoData?.user_info.is_staff ? "/catalog" : "/catalog"} />) : (<DeleteAccount />)} />
          <Route path="/signup" element={defaultProtectedRouteProps.isAuthenticated || login.loginSuccess ? (
            <Navigate replace to={userInfoData?.user_info.is_staff ? "/catalog" : "/catalog"} />) : (<Signup />)} />
          <Route path="/forget-password" element={defaultProtectedRouteProps.isAuthenticated || login.loginSuccess ? (
            <Navigate replace to={userInfoData?.user_info.is_staff ? "/catalog" : "/catalog"} />) : (<ForgotPassword />)} />
          <Route path="/otp" element={defaultProtectedRouteProps.isAuthenticated || login.loginSuccess ? (
            <Navigate replace to={userInfoData?.user_info.is_staff ? "/catalog" : "/catalog"} />) : (<Otp />)} />
          <Route path="/reset-password" element={defaultProtectedRouteProps.isAuthenticated || login.loginSuccess ? (
            <Navigate replace to={userInfoData?.user_info.is_staff ? "/catalog" : "/catalog"} />) : (<ResetPassword />)} />


          <Route path="/" element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<Main />} />}>
            <Route path="*" element={<Dummy />} />



 
 


          </Route>
        </Routes >
      </div >
    </>
  );
};

export default Navigation;
